// extracted by mini-css-extract-plugin
export var botInfoBox = "SmallList-module--botInfoBox--CNn70";
export var btnText = "SmallList-module--btnText--8YaWH";
export var customBTN = "SmallList-module--customBTN--oquOH";
export var customBorder = "SmallList-module--customBorder--8709P";
export var customBottom = "SmallList-module--customBottom--IRE-T";
export var description = "SmallList-module--description--3aT0K";
export var headline = "SmallList-module--headline--BjGPU";
export var image = "SmallList-module--image--XcGmB";
export var imageContainer = "SmallList-module--imageContainer--FHFqi";
export var imgBox = "SmallList-module--imgBox--pLKMh";
export var infoBox = "SmallList-module--infoBox--diUMW";
export var infoBoxBotDescription = "SmallList-module--infoBoxBotDescription--zc+8g";
export var infoBoxDescription = "SmallList-module--infoBoxDescription--+Wpd8";
export var infoBoxHeadline = "SmallList-module--infoBoxHeadline--XBgdo";
export var infoBoxIMG = "SmallList-module--infoBoxIMG--ANOqs";
export var infoBoxValue = "SmallList-module--infoBoxValue--AteTx";
export var listHeadline = "SmallList-module--listHeadline--GrbnX";
export var listItem = "SmallList-module--listItem--Vf9sV";
export var paddingLeft = "SmallList-module--paddingLeft--HvNim";
export var paddingRight = "SmallList-module--paddingRight--QIAWI";
export var smallListElement = "SmallList-module--smallListElement--P88XT";